import React from "react";

import { useGet } from "@/libs/promise_hook";
import routes from "@/libs/routes";
import api from "@/apis/$api";
import { getImageUrl } from "@/apis/images/@methods";
import axios from "@aspida/axios";
import FetchStatusIcon from "@/components/FetchStatusIcon";
import ImgCardList from "@/components/ImgCardList";
import PropTypes from "prop-types";
import ErrorPage from "@/pages/ErrorPage";
import TitleHeading from "@/components/TitleHeading";

import styles from "./CategoryDetail.module.scss";

interface Props {
  match: { params: { id: string } };
}

const CategoryIndex: React.FunctionComponent<Props> = (props) => {
  const { ret } = useGet(() =>
    api(axios()).categories._id(props.match.params.id).$get()
  );

  if (ret && "error" in ret)
    return <ErrorPage message="取得に失敗しました リロードをお試しください" />;

  if (ret === undefined) return <FetchStatusIcon state="pending" />;

  const category = ret.data;

  return (
    <>
      <TitleHeading>{category.name} 商品一覧</TitleHeading>
      <p id="catch" style={{ whiteSpace: "pre-wrap" }}>
        {category.description}
      </p>
      <ImgCardList
        cards={category.items.map((item) => ({
          text: (
            <>
              {item.name}{" "}
              <span className={styles.item_price}>
                {item.price.toLocaleString()} 円
              </span>
            </>
          ),
          img_src: item.image_ids ? getImageUrl(item.image_ids[0]) : "no-image",
          href: routes.ItemDetail.route.create({ id: String(item.id) }),
        }))}
      />
    </>
  );
};

CategoryIndex.propTypes = {
  match: PropTypes.exact({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CategoryIndex;
