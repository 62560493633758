import { Route, Switch, BrowserRouter } from "react-router-dom";
import { Route as TypedRoute, PathPart } from "typesafe-react-router";
import React from "react";
import PropTypes from "prop-types";
import GoogleAnalytics from "@/components/GoogleAnalytics";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PathPartArray = Array<PathPart<any>>;

type TypedRouteWithComponent<P extends PathPartArray, Q extends string[]> = {
  route: TypedRoute<P, Q>;
  component: React.ComponentType<{
    match: { params: Parameters<TypedRoute<P, Q>["create"]>[0] };
  }>;
};

export function typedRoute<P extends PathPartArray, Q extends string[]>(
  route: TypedRoute<P, Q>,
  component: React.ComponentType<{
    match: { params: Parameters<TypedRoute<P, Q>["create"]>[0] };
  }>
): TypedRouteWithComponent<P, Q> {
  return { route, component };
}

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routes: { [key: string]: TypedRouteWithComponent<any, any> };
  layout: React.ComponentType<{ children: React.ReactNode }>;
  fallback: React.FunctionComponent;
}

const TypesafeRouter: React.FunctionComponent<Props> = ({
  layout: Layout,
  ...props
}) => (
  <BrowserRouter>
    <GoogleAnalytics />
    <Layout>
      <Switch>
        {Object.values(props.routes).map((route) => (
          <Route
            exact
            key={route.route.template()}
            path={route.route.template()}
            component={route.component}
          />
        ))}
        <Route component={props.fallback} />
      </Switch>
    </Layout>
  </BrowserRouter>
);

TypesafeRouter.propTypes = {
  routes: PropTypes.any,
  layout: PropTypes.any,
  fallback: PropTypes.any,
};

export default TypesafeRouter;
