import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import BorderImage from "./BorderImage";

import styles from "./ImgCard.module.scss";

export interface Props {
  text: string | JSX.Element;
  img_src: string;
  href: string;
  children?: never;
}

const ImageCard: React.FunctionComponent<Props> = (props) => (
  <Link to={props.href} className={styles.img_card}>
    <BorderImage
      src={props.img_src}
      className={styles.image}
      hover_action="bold"
    />
    <p className={styles.text}>{props.text}</p>
  </Link>
);

ImageCard.propTypes = {
  text: PropTypes.string.isRequired,
  img_src: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default ImageCard;
