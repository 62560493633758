import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import api from "@/apis/$api";
import usePromise from "@/libs/promise_hook";
import axios from "@aspida/axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FetchStatusIcon from "@/components/FetchStatusIcon";

const LoginModal: React.FunctionComponent = () => {
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const login = usePromise(async () => {
    const pass = inputRef.current?.value;
    await api(axios()).auth.login.$post({
      body: { password: pass || "" },
      config: { withCredentials: true },
    });
    history.go(0); // reload
  });
  return (
    <Modal backdrop="static" show={true}>
      <Modal.Header>
        <Modal.Title>ログイン</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Group>
            <Form.Label>パスワード</Form.Label>
            <Form.Control type="password" ref={inputRef} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            login.clear();
            login.run();
          }}
        >
          ログイン
          <FetchStatusIcon state={login.state.state} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoginModal;
