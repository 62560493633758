import React from "react";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { contact_email } from "@/libs/site_common";

async function copyText(str: string) {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(str);
  } else {
    alert("コピーに対応していないようです。手動でのコピーをお願いいたします。");
  }
}

const EmailCopyBox: React.FunctionComponent = () => (
  <InputGroup className="mb-3">
    <InputGroup.Prepend>
      <InputGroup.Text style={{ backgroundColor: "transparent" }}>
        <a href={`mailto:${contact_email}`}>{contact_email}</a>
      </InputGroup.Text>
    </InputGroup.Prepend>
    <InputGroup.Append>
      <Button onClick={() => copyText(contact_email)}>コピー</Button>
    </InputGroup.Append>
  </InputGroup>
);

export default EmailCopyBox;
