import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./BorderImage.module.scss";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  src?: string;
  hover_action?: "bold" | "overlay" | "none";
  img_style?: React.CSSProperties;
};

const BorderImage: React.FunctionComponent<Props> = (props) => {
  const { src, img_style, hover_action, children, className, ...rest } = props;
  return (
    <div
      className={classNames(
        styles.wrapper,
        { [styles.hover_bold]: hover_action === "bold" },
        className
      )}
      {...rest}
    >
      <img className={styles.content} src={src} style={img_style} />
      <div className={styles.border} />
      {children && (
        <div
          className={classNames(styles.img_overlay, {
            [styles.overlay_hover]: hover_action === "overlay",
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};

BorderImage.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  img_style: PropTypes.object,
  hover_action: PropTypes.oneOf(["bold", "overlay", "none"]),
  children: PropTypes.node,
};

export default BorderImage;
