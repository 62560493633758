import React from "react";
import TitleHeading from "@/components/TitleHeading";
import EmailCopyBox from "@/components/EmailCopyBox";

import styles from "./Purchase.module.scss";

const Purchase: React.FunctionComponent = () => (
  <>
    <TitleHeading>購入方法・お問い合わせ</TitleHeading>
    <article style={{ paddingLeft: "1rem" }}>
      <section className={styles.section}>
        <h2 className={styles.section_header}>購入方法</h2>
        <ol>
          <li>
            ご購入の際は下記メールアドレス(お問い合わせ)へ、以下の情報を添えてご連絡ください。
            <ul>
              <li>
                商品番号(商品の個別ページからご確認ください)
                <ul>
                  <li>複数の商品をご注文の場合は全てお書きください</li>
                </ul>
              </li>
              <li>お名前</li>
              <li>郵便番号</li>
              <li>住所</li>
              <li>電話番号</li>
              <li>返信先メールアドレス(送信するメールアドレスと異なる場合)</li>
            </ul>
          </li>
          <li>送料を含めた金額と振込先をご連絡いたします。</li>
          <li>入金確認ができ次第、商品を発送させていただきます。</li>
        </ol>
      </section>
      <section className={styles.section}>
        <h2 className={styles.section_header}>お問い合わせ</h2>
        <p>
          <EmailCopyBox />
        </p>
        <p>陶芸家の娘 代表 杉山祐子</p>
      </section>
    </article>
  </>
);

export default Purchase;
