import React, { useState } from "react";

import PropTypes from "prop-types";
import styles from "./detail.module.scss";
import ErrorPage from "@/pages/ErrorPage";
import routes from "@/libs/routes";
import api from "@/apis/$api";
import axios from "@aspida/axios";
import { getImageUrl } from "@/apis/images/@methods";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import BorderImage from "@/components/BorderImage";
import TitleHeading from "@/components/TitleHeading";
import FetchStatusIcon from "@/components/FetchStatusIcon";
import { useGet } from "@/libs/promise_hook";

interface Props {
  match: { params: { id: string } };
}

const ItemDetail: React.FunctionComponent<Props> = (props) => {
  const { ret } = useGet(() =>
    api(axios()).items._id(Number(props.match.params.id)).$get()
  );
  const [shown_img, set_shown_img] = useState(0);

  if (ret && "error" in ret)
    return <ErrorPage message="取得に失敗しました リロードをお試しください" />;

  if (ret === undefined) return <FetchStatusIcon state="pending" />;

  const item = ret.data;

  return (
    <>
      <TitleHeading>{item.name}</TitleHeading>
      <ul className={styles.categories}>
        {item.categories.map((category) => (
          <Badge as="li" pill variant="secondary" key={category.id}>
            <Link to={routes.CategoryDetail.route.create({ id: category.id })}>
              <a>{category.name}</a>
            </Link>
          </Badge>
        ))}
      </ul>
      <div className={styles.row}>
        <div className={styles.imgs}>
          <BorderImage
            src={getImageUrl(item.image_ids[shown_img])}
            className={styles.main_img}
          />
          <div className={styles.thumb_imgs}>
            {item.image_ids.map((s, i) => (
              <BorderImage
                key={i}
                src={getImageUrl(s)}
                className={styles.image}
                onMouseOver={() => set_shown_img(i)}
                onClick={() => set_shown_img(i)}
              />
            ))}
          </div>
        </div>
        <div className={styles.content}>
          <p id="catch" style={{ whiteSpace: "pre-wrap" }}>
            {item.description}
          </p>
          <p className={styles.price}>
            <span>{item.price.toLocaleString()}円</span>
          </p>
          <p className={styles.itemId}>
            商品番号:<span>{item.id}</span>
          </p>
        </div>
      </div>
    </>
  );
};

ItemDetail.propTypes = {
  match: PropTypes.exact({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ItemDetail;
