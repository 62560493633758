import React from "react";
import ImageCard, { Props as ImgCardProps } from "./ImgCard";
import PropTypes from "prop-types";

import styles from "./ImgCardList.module.scss";
interface Props {
  cards: ImgCardProps[];
  children?: never;
}

const ImgCardList: React.FunctionComponent<Props> = (props) => (
  <ul className={styles.card_list}>
    {props.cards.map((c, i) => (
      <li key={i}>
        <ImageCard {...c} />
      </li>
    ))}
  </ul>
);

ImgCardList.propTypes = {
  cards: (ImageCard.propTypes
    ? PropTypes.arrayOf(PropTypes.shape(ImageCard.propTypes).isRequired)
    : PropTypes.array
  ).isRequired,
};

export default ImgCardList;
