import React, { useState } from "react";
import PropTypes from "prop-types";
import usePromise from "@/libs/promise_hook";
import api from "@/apis/$api";
import axios from "@aspida/axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FetchStatusIcon from "@/components/FetchStatusIcon";

// Item
export interface ItemProps {
  show: boolean;
  onHide: (need_reload: boolean) => void;
  itemId?: number; // empty for new
}

export const ItemDeleteModal: React.FunctionComponent<ItemProps> = (props) => {
  const [item_name, set_item_name] = useState<string | undefined>(undefined);

  const id = props.itemId;

  const getRequest = usePromise(async () => {
    set_item_name(undefined);
    if (id === undefined) return;

    const ret = await api(axios()).items._id(id).$get();

    set_item_name(ret.name);
  });

  const deleteRequest = usePromise(async () => {
    if (id === undefined) return;
    await api(axios())
      .items._id(id)
      .$delete({ config: { withCredentials: true } });

    props.onHide(true);
  });

  const fetching =
    getRequest.state.state != "fulfilled" ||
    deleteRequest.state.state == "pending";
  const submit_enabled = id !== undefined && !fetching;

  return (
    <Modal
      show={props.show}
      onEntering={() => {
        getRequest.clear();
        getRequest.run();
      }}
      onHide={() => props.onHide(false)}
      size="xl"
    >
      <Modal.Header>
        <Modal.Title>商品の削除</Modal.Title>
        <FetchStatusIcon state={getRequest.state.state} />
      </Modal.Header>
      <Modal.Body>
        <p>商品 {item_name} を削除してもよろしいですか?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide(false)}>
          キャンセル
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            deleteRequest.clear();
            deleteRequest.run();
          }}
          disabled={!submit_enabled}
        >
          削除
          <FetchStatusIcon state={deleteRequest.state.state} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ItemDeleteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  itemId: PropTypes.number,
};

// Category
export interface CategoryProps {
  show: boolean;
  onHide: (need_reload: boolean) => void;
  categoryId?: string; // empty for new
}

export const CategoryDeleteModal: React.FunctionComponent<CategoryProps> = (
  props
) => {
  const [category_name, set_category_name] = useState<string | undefined>(
    undefined
  );

  const id = props.categoryId;

  const getRequest = usePromise(async () => {
    set_category_name(undefined);
    if (id === undefined) return;

    const ret = await api(axios()).categories._id(id).$get();

    set_category_name(ret.name);
  });

  const deleteRequest = usePromise(async () => {
    if (id === undefined) return;
    await api(axios())
      .categories._id(id)
      .$delete({ config: { withCredentials: true } });

    props.onHide(true);
  });

  const fetching =
    getRequest.state.state != "fulfilled" ||
    deleteRequest.state.state == "pending";
  const submit_enabled = id !== undefined && !fetching;

  return (
    <Modal
      show={props.show}
      onEntering={() => {
        getRequest.clear();
        getRequest.run();
      }}
      onHide={() => props.onHide(false)}
      size="xl"
    >
      <Modal.Header>
        <Modal.Title>カテゴリの削除</Modal.Title>
        <FetchStatusIcon state={getRequest.state.state} />
      </Modal.Header>
      <Modal.Body>
        <p>カテゴリ {category_name} を削除してもよろしいですか?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide(false)}>
          キャンセル
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            deleteRequest.clear();
            deleteRequest.run();
          }}
          disabled={!submit_enabled}
        >
          削除
          <FetchStatusIcon state={deleteRequest.state.state} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CategoryDeleteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  categoryId: PropTypes.string,
};
