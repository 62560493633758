import React from "react";
import PropTypes from "prop-types";
import { site_name } from "@/libs/site_common";

interface Props {
  children?: string | string[];
  as?: React.ElementType;
}

const TitleHeading: React.FunctionComponent<Props> = (props) => {
  const Component = props.as || "h1";
  const in_title =
    props.children === undefined
      ? ""
      : Array.isArray(props.children)
      ? props.children.join("")
      : props.children;
  const title = in_title ? `${in_title} - ${site_name}` : site_name;
  React.useEffect(() => {
    const old_title = document.title;
    document.title = title;
    return () => {
      document.title = old_title;
    };
  }, [title]);
  return (
    <>
      <Component>{in_title || site_name}</Component>
    </>
  );
};

TitleHeading.propTypes = {
  children: PropTypes.string,
  as: PropTypes.elementType as PropTypes.Requireable<React.ElementType>,
};

export default TitleHeading;
