import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import usePromise from "@/libs/promise_hook";
import api from "@/apis/$api";
import axios from "@aspida/axios";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FetchStatusIcon from "./FetchStatusIcon";
import { AssertionError } from "assert";
import { getImageUrl } from "@/apis/images/@methods";

import BorderImage from "./BorderImage";

export interface Props {
  show: boolean;
  onHide: (need_reload: boolean) => void;
  categoryId?: string; // empty for new
}

const CategoryEditModal: React.FunctionComponent<Props> = (props) => {
  const [inputId, setInputId] = useState<string>("");
  const [inputName, setInputName] = useState<string>("");
  const [inputDescription, setInputDescription] = useState<string>("");
  const [inputIndex, setInputIndex] = useState<string>("");
  const [inputDistributed, setInputDistributed] = useState<boolean>(false);
  const [imageId, setImageId] = useState<string | null>(null);

  const imageInputRef = useRef<HTMLInputElement | null>(null);

  const getRequest = usePromise(async () => {
    setInputId("");
    setInputName("");
    setInputDescription("");
    setInputIndex("");
    setInputDistributed(false);
    setImageId(null);
    if (props.categoryId === undefined) return;

    const ret = await api(axios()).categories._id(props.categoryId).$get();
    setInputId(ret.id);
    setInputName(ret.name);
    setInputDescription(ret.description);
    setInputIndex(String(ret.index));
    setInputDistributed(ret.is_distributed);
    setImageId(ret.image_id);
  });

  const imageUploadRequest = usePromise(async () => {
    if (imageInputRef.current === null)
      throw new AssertionError({ message: "input ref must not be null" });
    const files = imageInputRef.current.files;
    if (files === null || files.length == 0) return;
    const ret = await api(axios()).images.$post({
      body: { content: files[0] },
      config: { withCredentials: true },
    });
    setImageId(ret.id);
  });

  const sendRequest = usePromise(async () => {
    if (imageId === null) return;
    const id = props.categoryId;
    const data = {
      id: inputId,
      name: inputName,
      description: inputDescription,
      index: Number.parseInt(inputIndex),
      is_distributed: inputDistributed,
      image_id: imageId,
    };
    if (id === undefined)
      await api(axios()).categories.$post({
        body: data,
        config: { withCredentials: true },
      });
    else
      await api(axios())
        .categories._id(id)
        .$patch({ body: data, config: { withCredentials: true } });

    props.onHide(true);
  });

  const fetching =
    getRequest.state.state != "fulfilled" ||
    imageUploadRequest.state.state == "pending" ||
    sendRequest.state.state == "pending";
  const is_name_valid = !!inputName.length;
  const is_id_valid = !!inputId.length;
  const is_id_invalid = !inputId.match(/^\w+$/);
  const is_description_valid = !!inputDescription.length;
  const is_index_valid = !!inputIndex.length;
  const is_index_invalid = !inputIndex.match(/-?[1-9]\d*|0/);
  const submit_enabled =
    !fetching &&
    is_name_valid &&
    is_id_valid &&
    !is_id_invalid &&
    is_description_valid &&
    is_index_valid &&
    !is_index_invalid &&
    imageId !== null;

  return (
    <Modal
      backdrop="static"
      size="lg"
      show={props.show}
      onEntering={() => {
        getRequest.clear();
        getRequest.run();
      }}
      onHide={() => props.onHide(false)}
    >
      <Modal.Header>
        <Modal.Title>かてごりの編集</Modal.Title>
        <FetchStatusIcon state={getRequest.state.state} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Group>
            <Form.Label>名前</Form.Label>
            <Form.Control
              type="text"
              value={inputName}
              onChange={(e) => setInputName(e.target.value)}
              disabled={fetching}
              isValid={is_name_valid}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>ID</Form.Label>
            <Form.Control
              type="text"
              value={inputId}
              onChange={(e) => setInputId(e.target.value)}
              disabled={fetching}
              isValid={is_id_valid}
              isInvalid={is_id_invalid}
            />
            <Form.Text className="text-muted">
              URLの一部になります 半角文字のみ
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>説明</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={inputDescription}
              onChange={(e) => setInputDescription(e.target.value)}
              disabled={fetching}
              isValid={is_description_valid}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>順位</Form.Label>
            <Form.Control
              type="text"
              value={inputIndex}
              onChange={(e) => setInputIndex(e.target.value)}
              disabled={fetching}
              isValid={is_index_valid}
              isInvalid={is_index_invalid}
            />
            <Form.Text className="text-muted">
              数が小さいほど先に表示されます
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="代理販売"
              checked={inputDistributed}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInputDistributed(e.target.checked)
              }
              disabled={fetching}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>画像</Form.Label>
            {imageId ? (
              <BorderImage
                src={getImageUrl(imageId)}
                hover_action="overlay"
                style={{ cursor: "pointer" }}
                onClick={() => setImageId(null)}
              >
                クリックして変更
              </BorderImage>
            ) : (
              <>
                <Form.File
                  ref={imageInputRef}
                  onChange={() => {
                    imageUploadRequest.clear();
                    imageUploadRequest.run();
                  }}
                  disabled={fetching}
                  isInvalid={imageUploadRequest.state.state !== "pending"}
                  feedback="画像は必須です"
                />
                <FetchStatusIcon state={imageUploadRequest.state.state} />
              </>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide(false)}>
          キャンセル
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (getRequest.state.state != "fulfilled") return;
            sendRequest.clear();
            sendRequest.run();
          }}
          disabled={!submit_enabled}
        >
          保存
          <FetchStatusIcon state={sendRequest.state.state} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CategoryEditModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  categoryId: PropTypes.string,
};

export default CategoryEditModal;
