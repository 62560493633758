import React from "react";
import NotFound from "./NotFound";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import TitleHeading from "@/components/TitleHeading";

interface Props {
  children?: never;

  status?: number;
  message?: string;
}

const ErrorPage: React.FunctionComponent<Props> = (props) => {
  const status = props.status || 500;
  const message = props.message || "内部エラーが発生しました。";

  if (status == 404) {
    return <NotFound />;
  }

  return (
    <>
      <TitleHeading>{message}</TitleHeading>
      <h2>
        {status} {http_errors[status]}
      </h2>
      <p>
        <Link to="/">
          <a>トップページへ戻る</a>
        </Link>
      </p>
    </>
  );
};

ErrorPage.propTypes = {
  status: PropTypes.number,
  message: PropTypes.string,
};

export default ErrorPage;

export const http_errors: { [key: number]: string | undefined } = {
  400: "Bad Request",
  401: "Unauthorized",
  402: "Payment Required ",
  403: "Forbidden",
  404: "Not Found",
  405: "Method Not Allowed",
  406: "Not Acceptable",
  407: "Proxy Authentication Required",
  408: "Request Timeout",
  409: "Conflict",
  410: "Gone",
  411: "Length Required",
  412: "Precondition Failed",
  413: "Payload Too Large",
  414: "URI Too Long",
  415: "Unsupported Media Type",
  416: "Range Not Satisfiable",
  417: "Expectation Failed",
  418: "I'm a teapot",
  421: "Misdirected Request",
  422: "Unprocessable Entity",
  423: "Locked",
  424: "Failed Dependency",
  425: "Too Early",
  426: "Upgrade Required",
  428: "Precondition Required",
  429: "Too Many Requests",
  431: "Request Header Fields Too Large",
  451: "Unavailable For Legal Reasons",
  500: "Internal Server Error",
  501: "Not Implemented",
  502: "Bad Gateway",
  503: "Service Unavailable",
  504: "Gateway Timeout",
  505: "HTTP Version Not Supported",
  506: "Variant Also Negotiates",
  507: "Insufficient Storage",
  508: "Loop Detected",
  510: "Not Extended",
  511: "Network Authentication Required",
};
