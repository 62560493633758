import { typedRoute } from "@/components/TypesafeRouter";
import { route, param } from "typesafe-react-router";

import Home from "@/pages/Home";
import About from "@/pages/About";
import Purchase from "@/pages/Purchase";
import CategoryList from "@/pages/categories/CategoryList";
import CategoryDetail from "@/pages/categories/CategoryDetail";
import ItemDetail from "@/pages/items/ItemDetail";
import Admin from "@/pages/Admin";

const routes = {
  Home: typedRoute(route(""), CategoryList),
  About: typedRoute(route("about"), About),
  Purchase: typedRoute(route("purchase"), Purchase),

  CategoryList: typedRoute(route("categories"), CategoryList),
  DistributedCategoryList: typedRoute(
    route("categories", "distributes"),
    CategoryList
  ),
  CategoryDetail: typedRoute(route("categories", param("id")), CategoryDetail),
  ItemDetail: typedRoute(route("items", param("id")), ItemDetail),

  Admin: typedRoute(route("admin"), Admin),
};

export default routes;
