import React from "react";
import TitleHeading from "@/components/TitleHeading";

import styles from "./About.module.scss";

const About: React.FunctionComponent = () => (
  <>
    <TitleHeading>ABOUT</TitleHeading>

    <div className={styles.content}>
      <div className={styles.row}>
        <div>
          <section>
            <h3 className={styles.heading}>作者紹介</h3>
            <div className={styles.creator}>
              <div className={styles.name}>
                <span className={styles.kanji}>佐伯 良四郎</span>
                <span className={styles.ruby}>Ryoshiro Saeki</span>
              </div>
              <p style={{ paddingLeft: "1rem" }}>
                陶芸家、日本画家。
                <br />
                日本画家として多摩総合美術展特賞ほか受賞。
                <br />
                陶芸家として陶芸財団展、全陶展等入賞作品多数。
              </p>
            </div>
          </section>
          <section>
            <h3 className={styles.heading}>運営者</h3>
            <ul className={styles.managers}>
              <li className={styles.name}>
                <span className={styles.kanji}>杉山 祐子</span>
                <span className={styles.ruby}>Yuko Sugiyama</span>
              </li>
              <li className={styles.name}>
                <span className={styles.kanji}>杉山 衣吹</span>
                <span className={styles.ruby}>Ibuki Sugiyama</span>
              </li>
            </ul>
          </section>
        </div>
        <section className={styles.links}>
          <h3 className={styles.heading}>リンク</h3>
          <ul>
            <li>
              <a href="http://www.tama.or.jp/~saeki/">
                <img src="/imgs/ichinomiya_banner.png" alt="一の宮美術館" />
              </a>
              <p>佐伯良四郎氏のホームページ</p>
            </li>
            <li>
              <a href="https://musuviva.theshop.jp">
                <img src="/imgs/musuviva_banner.jpg" alt="木の葉天目 むすびば堂" />
              </a>
              <p>姉妹店 むすびば堂</p>
            </li>
          </ul>
        </section>
      </div>
      {/*<section>
        <h2 className={styles.heading}>作品紹介</h2>
        </section>*/}
    </div>
  </>
);

export default About;
