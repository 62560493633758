import React from "react";
import ImgCardList from "@/components/ImgCardList";
import ErrorPage from "@/pages/ErrorPage";
import { useGet } from "@/libs/promise_hook";
import FetchStatusIcon from "@/components/FetchStatusIcon";
import api from "@/apis/$api";
import routes from "@/libs/routes";
import { getImageUrl } from "@/apis/images/@methods";
import axios from "@aspida/axios";
import { useLocation } from "react-router-dom";

import TitleHeading from "@/components/TitleHeading";

const CategoryList: React.FunctionComponent = () => {
  const loc = useLocation();
  const distributed_filter =
    loc.pathname === routes.DistributedCategoryList.route.create({});

  const { ret } = useGet(() => api(axios()).categories.$get());

  const categories = React.useMemo(() => {
    if (!ret || "error" in ret) return [];
    return ret.data.filter((cat) => cat.is_distributed === distributed_filter);
  }, [ret, distributed_filter]);

  if (ret && "error" in ret)
    return <ErrorPage message="取得に失敗しました リロードをお試しください" />;

  return (
    <>
      <TitleHeading>
        {distributed_filter ? "代理販売 " : "" + "商品一覧"}
      </TitleHeading>
      {ret !== undefined ? (
        <ImgCardList
          cards={categories.map((s) => ({
            text: s.name,
            img_src: getImageUrl(s.image_id),
            href: routes.CategoryDetail.route.create({ id: s.id }),
          }))}
        />
      ) : (
        <FetchStatusIcon state="pending" />
      )}
    </>
  );
};

export default CategoryList;
