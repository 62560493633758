import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { site_name } from "@/libs/site_common";
import routes from "@/libs/routes";

import styles from "./main.module.scss";

interface Props {
  children: React.ReactNode;
}

const MainLayout: React.FunctionComponent<Props> = (props) => {
  return (
    <>
      <div className={styles.main_layout_container}>
        <header id="navbar">
          <Link id="navbar-brand" to={routes.Home.route.create({})}>
            {site_name}
          </Link>
          <nav>
            <ul>
              {[
                {
                  to: routes.Home.route.create({}),
                  text: "HOME",
                  title: "ホーム",
                },
                {
                  to: routes.About.route.create({}),
                  text: "ABOUT",
                  title: "このホームページについて",
                },
                {
                  to: routes.DistributedCategoryList.route.create({}),
                  text: "代理販売",
                  title: "代理で販売している商品のカテゴリーの一覧",
                },
                {
                  to: routes.Purchase.route.create({}),
                  text: "購入方法・お問い合わせ",
                },
              ].map((a) => (
                <li key={a.to}>
                  <Link to={a.to} title={a?.title}>
                    {a.text}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </header>
        <div className={styles.main_wrapper}>
          <main>{props.children}</main>
        </div>
      </div>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
