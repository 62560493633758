import { useState, useEffect } from "react";
import { AssertionError } from "assert";

type PromiseState<T> = {
  promise_id: number;
} & (
  | {
      state: "idle";
    }
  | (
      | {
          state: "pending";
        }
      | {
          state: "fulfilled";
          value: T;
        }
      | {
          state: "rejected";
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          reason: any;
        }
    )
);

function usePromise<T>(
  fn: () => Promise<T>
): {
  state: PromiseState<T>;
  run: () => void;
  clear: () => void;
} {
  const [state, setState] = useState<PromiseState<T>>({
    promise_id: 0,
    state: "idle",
  });

  useEffect(() => {
    if (state.state != "pending") return;
    const promise = fn();
    const id = state.promise_id;
    promise
      .then((value) =>
        setState((s) => {
          if (s.promise_id == id)
            return {
              promise_id: id,
              state: "fulfilled",
              value,
            };
          else return s;
        })
      )
      .catch((reason) =>
        setState((s) => {
          if (s.promise_id == id)
            return {
              promise_id: id,
              state: "rejected",
              reason,
            };
          else return s;
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.state]);

  const run = () => {
    setState((current) => {
      if (current.state != "idle")
        throw new AssertionError({ message: "Duplicate Promise set" });
      return {
        promise_id: current.promise_id,
        state: "pending",
      };
    });
  };

  const clear = () => {
    setState((s) =>
      s.state === "pending"
        ? s
        : { promise_id: s.promise_id + 1, state: "idle" }
    );
  };

  return { state, run, clear };
}

export default usePromise;

export function useGet<T>(
  fn: () => Promise<T>
): { ret: { data: T } | { error: unknown } | undefined; reload: () => void } {
  const { state, run, clear } = usePromise(fn);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => run(), []);
  return {
    ret:
      state.state === "pending" || state.state === "idle"
        ? undefined
        : state.state === "rejected"
        ? { error: state.reason }
        : { data: state.value },
    reload: () => {
      clear();
      run();
    },
  };
}
