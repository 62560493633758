import React from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const GoogleAnalytics: React.FunctionComponent = () => {
  const ga_id = process.env.REACT_APP_GA_ID || "";

  const location = useLocation();

  React.useEffect(() => {
    ReactGA.initialize(ga_id);
  }, []);

  React.useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);

  return <></>;
};

export default GoogleAnalytics;
