import React, { useState } from "react";
import { useGet } from "@/libs/promise_hook";
import api from "@/apis/$api";
import axios from "@aspida/axios";
import is_axios_error from "@/libs/is_axios_error";
import routes from "@/libs/routes";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ErrorPage from "@/pages/ErrorPage";
import FetchStatusIcon from "@/components/FetchStatusIcon";

import { Link } from "react-router-dom";
import TitleHeading from "@/components/TitleHeading";

import LoginModal from "@/components/LoginModal";
import ItemEditModal from "@/components/ItemEditModal";
import CategoryEditModal from "@/components/CategoryEditModal";
import {
  ItemDeleteModal,
  CategoryDeleteModal,
} from "@/components/delete_modals";

import styles from "./Admin.module.scss";

interface ModalState<T> {
  id: T | undefined;
  show: undefined | "edit" | "delete";
}

const AdminTabs: React.FunctionComponent = () => {
  const [itemModal, setItemModal] = useState<ModalState<number>>({
    id: undefined,
    show: undefined,
  });
  const { ret: itemsRet, reload: itemsReload } = useGet(() =>
    api(axios()).items.$get({ config: { withCredentials: true } })
  );

  const [categoryModal, setCategoryModal] = useState<ModalState<string>>({
    id: undefined,
    show: undefined,
  });

  const { ret: categoriesRet, reload: categoriesReload } = useGet(() =>
    api(axios()).categories.$get({ config: { withCredentials: true } })
  );

  const reload = () => {
    itemsReload();
    categoriesReload();
  };

  if (
    (categoriesRet && "error" in categoriesRet) ||
    (itemsRet && "error" in itemsRet)
  )
    return <ErrorPage message="取得に失敗しました リロードをお試しください" />;

  const items = itemsRet !== undefined ? itemsRet.data : undefined;
  const categories =
    categoriesRet !== undefined ? categoriesRet.data : undefined;

  const items_tab = (
    <div>
      <div>
        <Button
          onClick={() =>
            setItemModal((a) => (a.show ? a : { id: undefined, show: "edit" }))
          }
          variant="primary"
        >
          新規作成
        </Button>
      </div>
      {items === undefined ? (
        <FetchStatusIcon state="pending" />
      ) : (
        <Table striped hover size="xl" responsive="sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>名前</th>
              <th>価格</th>
              <th>説明</th>
              <th>状態</th>
              <th>カテゴリ</th>
              <th>画像</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.id}>
                <th>{item.id}</th>
                <td>{item.name}</td>
                <td className={styles.column_price}>
                  {item.price.toLocaleString()}
                </td>
                <td className={styles.column_description}>
                  {item.description}
                </td>
                <td>{item.sold ? "売約済" : "販売中"}</td>
                <td>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip_item_categories_${item.id}`}>
                        <ul className={styles.categories_tooltip}>
                          {item.categories.map((cat, i) => (
                            <li key={`${i}_${cat.id}`}>{cat.name}</li>
                          ))}
                        </ul>
                      </Tooltip>
                    }
                  >
                    <span>{item.categories.length}件</span>
                  </OverlayTrigger>
                </td>
                <td>{item.image_ids.length}件</td>
                <th>
                  <ButtonGroup>
                    <Button
                      variant="secondary"
                      as={Link}
                      to={routes.ItemDetail.route.create({
                        id: item.id.toString(),
                      })}
                    >
                      表示
                    </Button>
                    <Button
                      variant="info"
                      onClick={() =>
                        setItemModal((a) =>
                          a.show ? a : { id: item.id, show: "edit" }
                        )
                      }
                    >
                      編集
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() =>
                        setItemModal((a) =>
                          a.show ? a : { id: item.id, show: "delete" }
                        )
                      }
                    >
                      削除
                    </Button>
                  </ButtonGroup>
                </th>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <ItemEditModal
        itemId={itemModal.id}
        show={itemModal.show === "edit"}
        onHide={(r) => {
          setItemModal({ id: undefined, show: undefined });
          if (r) reload();
        }}
      />
      <ItemDeleteModal
        itemId={itemModal.id}
        show={itemModal.show === "delete"}
        onHide={(r) => {
          setItemModal({ id: undefined, show: undefined });
          if (r) reload();
        }}
      />
    </div>
  );
  const categories_tab = (
    <div>
      <div>
        <Button
          onClick={() =>
            setCategoryModal((a) =>
              a.show ? a : { id: undefined, show: "edit" }
            )
          }
          variant="primary"
        >
          新規作成
        </Button>
      </div>
      {categories === undefined ? (
        <FetchStatusIcon state="pending" />
      ) : (
        <Table striped hover size="xl" responsive="sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>名前</th>
              <th>説明</th>
              <th>商品</th>
              <th>Idx</th>
              <th>代理販売</th>
              <th>編集</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category.id}>
                <th>{category.id}</th>
                <td>{category.name}</td>
                <td className={styles.column_description}>
                  {category.description}
                </td>
                <td>{category.item_ids.length}件</td>
                <td>{category.index}</td>
                <td>{category.is_distributed ? "o" : ""}</td>
                <th>
                  <ButtonGroup>
                    <Button
                      variant="secondary"
                      as={Link}
                      to={routes.CategoryDetail.route.create({
                        id: category.id,
                      })}
                    >
                      表示
                    </Button>
                    <Button
                      variant="info"
                      onClick={() =>
                        setCategoryModal((a) =>
                          a.show ? a : { id: category.id, show: "edit" }
                        )
                      }
                    >
                      編集
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() =>
                        setCategoryModal((a) =>
                          a.show ? a : { id: category.id, show: "delete" }
                        )
                      }
                    >
                      削除
                    </Button>
                  </ButtonGroup>
                </th>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <CategoryEditModal
        categoryId={categoryModal.id}
        show={categoryModal.show === "edit"}
        onHide={(r) => {
          setCategoryModal({ id: undefined, show: undefined });
          if (r) reload();
        }}
      />
      <CategoryDeleteModal
        categoryId={categoryModal.id}
        show={categoryModal.show === "delete"}
        onHide={(r) => {
          setCategoryModal({ id: undefined, show: undefined });
          if (r) reload();
        }}
      />
    </div>
  );
  return (
    <Tabs id="admin-tabs" style={{ marginBottom: "1rem" }}>
      <Tab eventKey="items" title="商品">
        {items_tab}
      </Tab>
      <Tab eventKey="categories" title="カテゴリー">
        {categories_tab}
      </Tab>
    </Tabs>
  );
};

const Admin: React.FunctionComponent = () => {
  const { ret } = useGet(() =>
    api(axios()).auth.user.$get({ config: { withCredentials: true } })
  );

  if (ret && "error" in ret) {
    const error = ret.error;
    console.log(error);
    if (is_axios_error(error) && error.response?.status === 401)
      return <LoginModal />;
    else return <ErrorPage message={String(error)} />;
  }

  return (
    <>
      <TitleHeading>管理画面</TitleHeading>
      {ret === undefined ? <FetchStatusIcon state="pending" /> : <AdminTabs />}
    </>
  );
};

export default Admin;
