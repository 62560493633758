import React from "react";
import TypesafeRouter from "@/components/TypesafeRouter";
import routes from "@/libs/routes";
import MainLayout from "@/layouts/main";
import NotFound from "@/pages/NotFound";

const App: React.FunctionComponent = () => {
  return (
    <TypesafeRouter routes={routes} layout={MainLayout} fallback={NotFound} />
  );
};

export default App;
