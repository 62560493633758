/* eslint-disable */
import { AspidaClient } from 'aspida'
import { Methods as Methods0 } from '.'
import { Methods as Methods1 } from './auth/login'
import { Methods as Methods2 } from './auth/user'
import { Methods as Methods3 } from './categories'
import { Methods as Methods4 } from './categories/_id@string'
import { Methods as Methods5 } from './images'
import { Methods as Methods6 } from './items'
import { Methods as Methods7 } from './items/_id@number'

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '')
  const PATH0 = '/auth/login'
  const PATH1 = '/auth/user'
  const PATH2 = '/categories'
  const PATH3 = '/images'
  const PATH4 = '/items'
  const GET = 'GET'
  const POST = 'POST'
  const DELETE = 'DELETE'
  const PATCH = 'PATCH'

  return {
    auth: {
      login: {
        post: (option: { body: Methods1['post']['reqBody'], config?: T }) =>
          fetch<Methods1['post']['resBody']>(prefix, PATH0, POST, option).json(),
        $post: (option: { body: Methods1['post']['reqBody'], config?: T }) =>
          fetch<Methods1['post']['resBody']>(prefix, PATH0, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH0}`
      },
      user: {
        get: (option?: { config?: T }) =>
          fetch<Methods2['get']['resBody']>(prefix, PATH1, GET, option).json(),
        $get: (option?: { config?: T }) =>
          fetch<Methods2['get']['resBody']>(prefix, PATH1, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH1}`
      }
    },
    categories: {
      _id: (val0: string) => {
        const prefix0 = `${PATH2}/${val0}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods4['get']['resBody']>(prefix, prefix0, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods4['get']['resBody']>(prefix, prefix0, GET, option).json().then(r => r.body),
          patch: (option: { body: Methods4['patch']['reqBody'], config?: T }) =>
            fetch<Methods4['patch']['resBody']>(prefix, prefix0, PATCH, option).json(),
          $patch: (option: { body: Methods4['patch']['reqBody'], config?: T }) =>
            fetch<Methods4['patch']['resBody']>(prefix, prefix0, PATCH, option).json().then(r => r.body),
          delete: (option?: { config?: T }) =>
            fetch<Methods4['delete']['resBody']>(prefix, prefix0, DELETE, option).json(),
          $delete: (option?: { config?: T }) =>
            fetch<Methods4['delete']['resBody']>(prefix, prefix0, DELETE, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix0}`
        }
      },
      get: (option?: { config?: T }) =>
        fetch<Methods3['get']['resBody']>(prefix, PATH2, GET, option).json(),
      $get: (option?: { config?: T }) =>
        fetch<Methods3['get']['resBody']>(prefix, PATH2, GET, option).json().then(r => r.body),
      post: (option: { body: Methods3['post']['reqBody'], config?: T }) =>
        fetch<Methods3['post']['resBody']>(prefix, PATH2, POST, option).json(),
      $post: (option: { body: Methods3['post']['reqBody'], config?: T }) =>
        fetch<Methods3['post']['resBody']>(prefix, PATH2, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH2}`
    },
    images: {
      post: (option: { body: Methods5['post']['reqBody'], config?: T }) =>
        fetch<Methods5['post']['resBody']>(prefix, PATH3, POST, option, 'FormData').json(),
      $post: (option: { body: Methods5['post']['reqBody'], config?: T }) =>
        fetch<Methods5['post']['resBody']>(prefix, PATH3, POST, option, 'FormData').json().then(r => r.body),
      $path: () => `${prefix}${PATH3}`
    },
    items: {
      _id: (val1: number) => {
        const prefix1 = `${PATH4}/${val1}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods7['get']['resBody']>(prefix, prefix1, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods7['get']['resBody']>(prefix, prefix1, GET, option).json().then(r => r.body),
          patch: (option: { body: Methods7['patch']['reqBody'], config?: T }) =>
            fetch<Methods7['patch']['resBody']>(prefix, prefix1, PATCH, option).json(),
          $patch: (option: { body: Methods7['patch']['reqBody'], config?: T }) =>
            fetch<Methods7['patch']['resBody']>(prefix, prefix1, PATCH, option).json().then(r => r.body),
          delete: (option?: { config?: T }) =>
            fetch<Methods7['delete']['resBody']>(prefix, prefix1, DELETE, option).json(),
          $delete: (option?: { config?: T }) =>
            fetch<Methods7['delete']['resBody']>(prefix, prefix1, DELETE, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      get: (option?: { config?: T }) =>
        fetch<Methods6['get']['resBody']>(prefix, PATH4, GET, option).json(),
      $get: (option?: { config?: T }) =>
        fetch<Methods6['get']['resBody']>(prefix, PATH4, GET, option).json().then(r => r.body),
      post: (option: { body: Methods6['post']['reqBody'], config?: T }) =>
        fetch<Methods6['post']['resBody']>(prefix, PATH4, POST, option).json(),
      $post: (option: { body: Methods6['post']['reqBody'], config?: T }) =>
        fetch<Methods6['post']['resBody']>(prefix, PATH4, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH4}`
    },
    get: (option?: { config?: T }) =>
      fetch<Methods0['get']['resBody']>(prefix, '', GET, option).text(),
    $get: (option?: { config?: T }) =>
      fetch<Methods0['get']['resBody']>(prefix, '', GET, option).text().then(r => r.body),
    $path: () => `${prefix}${''}`
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api
