import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import usePromise from "@/libs/promise_hook";
import api from "@/apis/$api";
import axios from "@aspida/axios";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import FetchStatusIcon from "@/components/FetchStatusIcon";
import { AssertionError } from "assert";
import { getImageUrl } from "@/apis/images/@methods";

import BorderImage from "@/components/BorderImage";
import { ItemParameter } from "@/apis/items/@types";
import { CategoryParameter } from "@/apis/categories/@types";

import styles from "./ItemEditModal.module.scss";

export interface Props {
  show: boolean;
  onHide: (need_reload: boolean) => void;
  itemId?: number; // empty for new
}

const ItemEditModal: React.FunctionComponent<Props> = (props) => {
  const [inputName, setInputName] = useState<string>("");
  const [inputPrice, setInputPrice] = useState<string>("");
  const [inputDescription, setInputDescription] = useState<string>("");
  const [inputCategories, setInputCategories] = useState<{
    [key: string]: boolean;
  }>({});
  const [categories, setCategories] = useState<CategoryParameter[]>([]);
  const [images, setImages] = useState<string[]>([]);

  const removeImage = (idx: number) => {
    setImages((imgs) => imgs.filter((_v, i) => i != idx));
  };

  const swapImage = (idx1: number, idx2: number) => {
    setImages((imgs) => {
      if (0 > idx1 || idx1 >= imgs.length || 0 > idx2 || idx2 >= imgs.length)
        return imgs;
      const imgs_cpy = ([] as string[]).concat(imgs);
      imgs_cpy[idx1] = imgs[idx2];
      imgs_cpy[idx2] = imgs[idx1];
      return imgs_cpy;
    });
  };

  const imageInputRef = useRef<HTMLInputElement | null>(null);

  const id = props.itemId;

  const getRequest = usePromise(async () => {
    setInputName("");
    setInputPrice("");
    setInputDescription("");
    setCategories([]);
    setImages([]);

    setCategories(await api(axios()).categories.$get());

    if (id === undefined) return;

    const ret = await api(axios()).items._id(id).$get();

    setInputName(ret.name);
    setInputPrice(ret.price.toString());
    setInputDescription(ret.description);
    setInputCategories(
      Object.fromEntries(ret.categories.map((cat) => [cat.id, true]))
    );
    setImages(ret.image_ids);
  });
  const imageUploadRequest = usePromise(async () => {
    if (imageInputRef.current === null)
      throw new AssertionError({ message: "input ref must not be null" });
    try {
      const files = imageInputRef.current.files;
      if (files === null || files.length == 0) return;
      const ret = await api(axios()).images.$post({
        body: { content: files[0] },
        config: { withCredentials: true },
      });
      setImages((a) => [...a, ret.id]);
    } finally {
      imageInputRef.current.value = ""; // reset choice
    }
  });

  const sendRequest = usePromise(async () => {
    const data: ItemParameter = {
      name: inputName,
      price: Number.parseInt(inputPrice),
      description: inputDescription,
      image_ids: images,
      category_ids: Object.entries(inputCategories).flatMap((a) =>
        a[1] ? a[0] : []
      ),
    };
    if (id === undefined)
      await api(axios()).items.$post({
        body: data,
        config: { withCredentials: true },
      });
    else
      await api(axios())
        .items._id(id)
        .$patch({ body: data, config: { withCredentials: true } });

    props.onHide(true);
  });

  const fetching =
    getRequest.state.state != "fulfilled" ||
    imageUploadRequest.state.state == "pending" ||
    sendRequest.state.state == "pending";
  const is_name_valid = !!inputName.length;
  const is_price_valid = !!inputPrice.length;
  const is_price_invalid = !inputPrice.match(/^(?:|0|[1-9]\d*)$/);
  const is_description_valid = !!inputDescription.length;
  const submit_enabled =
    !fetching &&
    is_name_valid &&
    is_price_valid &&
    !is_price_invalid &&
    is_description_valid &&
    images.length != 0;

  return (
    <Modal
      backdrop="static"
      show={props.show}
      onEntering={() => {
        getRequest.clear();
        getRequest.run();
      }}
      onHide={() => props.onHide(false)}
      size="xl"
    >
      <Modal.Header>
        <Modal.Title>商品の編集</Modal.Title>
        <FetchStatusIcon state={getRequest.state.state} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Group>
            <Form.Label>名前</Form.Label>
            <Form.Control
              type="text"
              value={inputName}
              onChange={(e) => setInputName(e.target.value)}
              disabled={fetching}
              isValid={is_name_valid}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>価格</Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                value={inputPrice}
                onChange={(e) => setInputPrice(e.target.value)}
                disabled={fetching}
                isValid={is_price_valid}
                isInvalid={is_price_invalid}
              />
              <InputGroup.Append>
                <InputGroup.Text>円</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label>説明</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={inputDescription}
              onChange={(e) => setInputDescription(e.target.value)}
              disabled={fetching}
              isValid={is_description_valid}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>カテゴリ</Form.Label>
            {categories.map((category) => (
              <Form.Check
                key={category.id}
                id={`item-category-checkbox-${category.id}`}
                type="checkbox"
                value={category.id}
                label={category.name}
                checked={!!inputCategories[category.id]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.checked;
                  setInputCategories((catobj) => ({
                    ...catobj,
                    [category.id]: value,
                  }));
                }}
              />
            ))}
          </Form.Group>
          <Form.Group>
            <Form.Label>画像</Form.Label>
            <Form.File
              ref={imageInputRef}
              onChange={() => {
                imageUploadRequest.clear();
                imageUploadRequest.run();
              }}
              disabled={fetching}
              isInvalid={
                imageUploadRequest.state.state !== "pending" &&
                images.length == 0
              }
              feedback="画像は必須です"
            />
            <FetchStatusIcon state={imageUploadRequest.state.state} />
          </Form.Group>
          <p>画像をクリックして削除</p>
          <div className={styles.images}>
            {images.map((imageId, idx) => (
              <div key={imageId}>
                <BorderImage
                  src={getImageUrl(imageId)}
                  hover_action="overlay"
                  className={styles.image}
                  style={{ cursor: "pointer", width: "15rem", height: "15rem" }}
                  onClick={() => removeImage(idx)}
                >
                  クリックして削除
                </BorderImage>
                <ButtonGroup>
                  <Button
                    onClick={() => swapImage(idx, idx - 1)}
                    disabled={idx == 0}
                  >
                    &lt;
                  </Button>
                  <Button
                    onClick={() => swapImage(idx, idx + 1)}
                    disabled={idx == images.length - 1}
                  >
                    &gt;
                  </Button>
                  <Button onClick={() => removeImage(idx)}>x</Button>
                </ButtonGroup>
              </div>
            ))}
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide(false)}>
          キャンセル
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (getRequest.state.state != "fulfilled") return;
            sendRequest.clear();
            sendRequest.run();
          }}
          disabled={!submit_enabled}
        >
          保存
          <FetchStatusIcon state={sendRequest.state.state} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ItemEditModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  itemId: PropTypes.number,
};

export default ItemEditModal;
