import React from "react";
import { Link } from "react-router-dom";
import routes from "@/libs/routes";

const NotFound: React.FunctionComponent = () => (
  <>
    <h1>お探しのページは存在しません。</h1>
    <p style={{ fontSize: "2rem" }}>404 Not Found</p>
    <p>お探しのページは存在しません。URLを確認してください。</p>
    <Link to={routes.Home.route.create({})}>トップページへ戻る</Link>
  </>
);

export default NotFound;
